<template>
  <div id="app" :lang="$i18n.locale">
      <ResizeBox>
          <ContainerBox>
              <transition name="fade" mode="out-in">
                  <router-view />
              </transition>
          </ContainerBox>
      </ResizeBox>
  </div>
</template>

<script>
import ResizeBox from "@/components/layout/ResizeBox.vue";
import ContainerBox from "@/components/layout/ContainerBox.vue";

export default {
  name: "App",
  components: {
    ResizeBox,
    ContainerBox,
  },
  async created() {
    // load data
    await this.$store.dispatch("loadFromBackend");


    // document.ontouchmove = function(event){
    //     event.preventDefault();
    // }

    // set default language
                                  // if (!$cookies.isKey("LangCode")) {
                                  //   $cookies.set("LangCode", "zh");
                                  // }

    // console.log( this.$store );
    // console.log( this.$store.floorsInfo );
  },
};
</script>

<style lang="scss">
/* font */
// @font-face {
//   font-family: titleRegular;
//   src: url("@/assets/font/Amatic_SC/AmaticSC-Regular.ttf");
// }
//
// @font-face {
//   font-family: titleBold;
//   src: url("@/assets/font/Amatic_SC/AmaticSC-Bold.ttf");
// }
//
// @font-face {
//   font-family: contentRegular;
//   src: url("@/assets/font/Gloria_Hallelujah/GloriaHallelujah-Regular.ttf");
// }

@font-face {
  font-family: Quicksand;
  src: url("@/assets/font/Quicksand/Quicksand-Light.ttf");
  font-weight: 300;
}

@font-face {
  font-family: Quicksand;
  src: url("@/assets/font/Quicksand/Quicksand-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: Quicksand;
  src: url("@/assets/font/Quicksand/Quicksand-Medium.ttf");
  font-weight: 500;
}

@font-face {
  font-family: Quicksand;
  src: url("@/assets/font/Quicksand/Quicksand-Bold.ttf");
  font-weight: 700;
}

* {
  font-family: Quicksand;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
}

#app {
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  // color: #2c3e50;
  color: #000000;
  background: black;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

html,
body,
#app {
  padding: 0;
  margin: 0;

  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

html,
body,
#app {
  // height: 100vh;
}

// transition animation
.fade-enter-active,
.fade-leave-active {
  transition: all 0.2s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-leave,
.fade-enter-to {
  opacity: 1;
}

// helper item
// .no-select {
* {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

* {
  -webkit-tap-highlight-color: transparent;
  // touch-action: none;
  // pointer-events: none;
}

// input, button, a {
  // pointer-events: auto;
// }


img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

.box-button {
  height: 50px;
  padding: 0 1.5em;
  background: lightgrey;

  font-size: 20px;
  font-weight: bold;
  border: none;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

// --- custom ---
.wave-background {
  background-image: url("@/assets/img/bg.png");
  background-size: cover;
  background-position: center;

  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

/* width */
.custom-scrollbar::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
