<template lang="html">
  <div class="container" :class="{ padding: padding }">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "ContainerBox",
  props: {
    padding: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  /* height: 1024px; */

  height: 100%;
  width: 100%;
  max-width: 1366px;
  margin: auto;
  /* margin-left: auto;
  margin-right: auto; */

  box-sizing: border-box;

  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;

  overflow: hidden;
}

.container.padding {
  padding: 20px;
}
</style>
