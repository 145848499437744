<template lang="html">
    <div class="resize-box" :style="{'scale': scale}">
        <slot />
    </div>
</template>

<script>
export default {
  name: "ResizeBox",
  mounted(){
    this.onResize();
    window.addEventListener("resize", this.onResize);
  },
  data(){
    return {
      boxWidth: 1366,
      boxHeight: 1024,
      scale: 1,
    }
  },
  methods: {
    onResize(){
      const containerWidth = document.documentElement.clientWidth;
      const containerHeight = document.documentElement.clientHeight;

      if( containerWidth / containerHeight > this.boxWidth / this.boxHeight ){
          this.scale = containerHeight / this.boxHeight;
      } else {
          this.scale = containerWidth / this.boxWidth;
      }
    }
  }
}
</script>

<style lang="scss" scoped>

$box-width: 1366px;
$box-height: 1024px;

.resize-box{
  position: absolute;
  width: $box-width;
  height: $box-height;

  top: calc( ( 100vh - $box-height ) / 2 );
  left: calc( ( 100vw - $box-width ) / 2 );
}

</style>
